<template>
  <div class="n_bg">
    <openApp @getshowimg="getshowimg" />
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
    <div
      class="couponDetail"
      :class="{
        noCanGet: noCanGet,
        couponDetailActivity: type != 7 && type != 5,
      }"
    >
      <div>
        <div class="left">
          <div>{{ couponInfo.couponName }}</div>
          <div class="price">
            <span>￥</span><span>{{ couponInfo.amount }}</span><span>优惠券</span>
          </div>
          <div>有效期:{{ couponInfo.usedRemind }}</div>
          <img
            v-if="!noCanGet"
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiy2LqLVhxxJ1601022447.png"
            alt=""
          >
          <img
            v-else
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuilekQr9PFpR1601022466.png"
            alt=""
          >
        </div>
        <div class="right" @click="getCoupons">
          <!-- <div>立即领取</div> -->
          <div v-if="!noCanGet">立即领取</div>
          <div v-else>立即使用</div>
        </div>
      </div>
    </div>
    <img
      v-if="type != 7 && type != 5"
      :src="img"
      alt=""
      class="activityImg"
      @click="goodsDetail"
    >

    <div v-if="type != 7 && type != 5" class="activity" @click="goodsDetail">
      查看活动
    </div>
    <div v-if="type == 7" class="goodsDetail" @click="goodsDetail">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-15/21/yuelvhui3eTHPKuuBV1581774315.png"
        alt=""
      >
    </div>
    <div class="explain" @click="goodsDetail">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuide1IFeNHiy1601022516.png"
        alt=""
      >
      <div>
        <span v-for="item of remind" :key="item">{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import openApp from '../../component/GoodsDetail/openApp'
import {
  ajaxGetCoupon,
  ajaxShareCouponDetail, ajaxThreeCouponH5Share
} from '@/services/couponPage'
import res from '@/stores/getters'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'DetailActivity',
  components: {
    openApp
  },
  data() {
    return {
      couponInfo: {},
      goodsInfo: {},
      shareInfo: {},
      remind: [],
      noCanGet: false,
      reCode: '',
      cid: '',
      parentId: '',
      codeNumber: '',
      img: '',
      type: 39, // 7-通用卷；39-活动卷(默认);5-加油
      showLoading: true,
      showimg: false
    }
  },
  mounted() {
    this.$store.commit('changeInterceptUrl', window.location.href)
    this.$store.dispatch('loginIntercept', this.$route.query)
    this.initData()
    const that = this
    /** *判断访问终端***/
    this.browser = {
      versions: (function() {
        var u = navigator.userAgent
        // var app = navigator.appVersion
        return {
          trident: u.indexOf('Trident') > -1, // IE内核
          presto: u.indexOf('Presto') > -1, // opera内核
          webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
          gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*!/), // 是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
          iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, // 是否iPad
          webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
          weixin: isWeChat(), // 是否微信 （2015-01-22新增）
          qq: u.match(/\sQQ/i) === ' qq' // 是否QQ
        }
      })(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }
    if (isWeChat()) {
      // 在微信中打开
      that.wechat = true
      // that.share();
    }
  },
  methods: {
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    initData() {
      const that = this
      this.cid = this.$route.query.cid
      this.parentId = this.$route.query.parentId || false
      this.codeNumber = this.$route.query.codeNumber
      this.type = this.$route.query.type || 39
      if (isWeChat()) {
        // 在微信中打开
        that.wechat = true
        if (!localStorage.getItem('uid')) {
          that.wxlogin()
        } else {
          that.couponsData()
        }
      } else {
        that.showLoading = false
        that.couponsData()
      }
    },
    couponsData() {
      const that = this
      const data = {
        cid: this.cid,
        couponActivityId: 1,
        uid: this.parentId,
        memberId: localStorage.getItem('uid') // 领取者的ID
      }
      ajaxShareCouponDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          that.couponInfo = res.data.couponInfo
          that.goodsInfo = res.data.goodsInfo
          that.remind = res.data.remind
          that.img = res.data.img
          that.oid = res.data.id
          that.share()
          // eslint-disable-next-line eqeqeq
          if (res.data.state == 1) {
            that.noCanGet = true
            that.showLoading = false
          }
          that.showLoading = false
        } else {
          Toast(res.msg)
          that.noCanGet = true
          that.showLoading = false
        }
      })
    },
    getCoupons() {
      if (this.noCanGet) {
        this.goodsDetail()
        return
      }
      const that = this
      const data = {
        uid: this.parentId, // 分享者的id 32185
        cid: this.cid, // 优惠券的ID 1834
        memberId: localStorage.getItem('uid') // 领取者的ID
      }

      // 暂时关闭
      // eslint-disable-next-line eqeqeq
      if (data.cid == 2564) {
        return
      }
      ajaxGetCoupon(data).then((res) => {
        if (Number(res.code) === 200) {
          Toast('领取成功')
          window.location.reload()
        } else {
          // 暂时关闭
          // eslint-disable-next-line eqeqeq
          if (data.cid == 2564) {
            return
          }
          Toast(res.msg)
          that.noCanGet = true
        }
      })
    },
    goodsDetail() {
      // 暂时关闭
      // eslint-disable-next-line eqeqeq
      if (res.uid == 2564) {
        return
      }
      // this.goApp()
      this.$router.push('/Activitylist?oid=' +
        this.oid +
        '&room_id=' +
        this.getQueryVariable('room_id'))
    },
    getQueryVariable: function(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    urlencode(str) {
      str = (str + '').toString()
      return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+')
    },
    goApp() {
      if (this.browser.versions.ios) {
        // ios
        window.location.href = 'yuelvhui://'
        setTimeout(function() {
          window.location.href =
            'https://itunes.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408?mt=8'
        }, 1000)
      } else {
        // android
        window.location.href = 'yuelvhui://'
        setTimeout(function() {
          window.location =
            'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www'
        }, 1000)
      }
    },
    share() {
      const that = this
      // eslint-disable-next-line eqeqeq
      if (that.type == 7) {
        that.shareInfo = {
          title: '悦淘官方又发福利啦，送你一张全场通用劵',
          desc: '全场通用，数量有限，手慢无（白拿和半价商品除外）',
          imgUrl:
            'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-15/21/yuelvhui3eTHPKuuBV1581774315.png',
          link: window.location.href
        }
        // eslint-disable-next-line eqeqeq
      } else if (that.type == 5) {
        ajaxThreeCouponH5Share({ cid: that.cid }).then((res) => {
          if (Number(res.code) === 200) {
            that.shareInfo = {
              title: res.title,
              desc: res.des,
              imgUrl: res.img,
              link: window.location.href
            }
          }
        })
      } else {
        that.shareInfo = {
          title: that.couponInfo.couponName,
          desc: '￥' + that.couponInfo.amount + '优惠券',
          imgUrl: that.img,
          link: window.location.href
        }
      }
      wxConfigInit(that.shareInfo)
      // this.$store.dispatch('wxConfigInit', that.shareInfo)
    }
  }
}
</script>
<style lang="less" scoped>
.couponDetailActivity {
  height: 2.61rem;
  background: #fff3ea;
  padding-top: 0.3rem;
}

.activityImg {
  width: 100%;
  display: block;
}

.activity {
  width: 4.04rem;
  height: 0.8rem;
  background: linear-gradient(90deg,
  rgba(234, 64, 90, 1),
  rgba(248, 108, 99, 1));
  box-shadow: 0px 0.05rem 0.1rem 0px rgba(248, 59, 22, 0.4);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -0.5rem;
  color: #fff;
  font-size: 0.3rem;
  position: relative;
  margin-top: 0.4rem;
}

.couponDetail {
  width: 100%;
  height: 4.04rem;
  margin-top: 0.85rem;
  // background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhui0Pi6MCcpbE1601022332.png");
  background-size: 100% 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
  position: relative;
}

.couponDetail > div {
  height: 3rem;
  margin: 0 5%;
  border-radius: 0.18rem;
  display: flex;
  background: #fff;
}

.couponDetail .left {
  width: 5.694rem;
  height: 100%;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiZtwt2pCacS1601022349.png");
  box-sizing: border-box;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.22rem;
  color: #ffbec7;
  position: relative;
}

.couponDetail .left > div:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.28rem;
  width: 100%;
  color: #fff;
  padding: 0 0.2rem;
  box-sizing: border-box;
  text-align: center;
}

.couponDetail .left .price {
  color: #fff;
  font-size: 0.3rem;
}

.couponDetail .left .price span:nth-child(1) {
  font-size: 0.4rem;
}

.couponDetail .left .price span:nth-child(2) {
  font-size: 0.88rem;
  padding-right: 0.13rem;
}

.couponDetail .left img {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.43rem;
  height: 1.144rem;
}

.couponDetail .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0 0.18rem 0.18rem 0;
}

.couponDetail .right > div {
  width: 1.7rem;
  height: 0.88rem;
  background: linear-gradient(90deg,
  rgba(234, 64, 90, 1),
  rgba(248, 108, 99, 1));
  border-radius: 0.34rem;
  color: #fff;
  font-size: 0.22rem;
  text-align: center;
  line-height: 0.88rem;
}

.noCanGet .left {
  line-height: 1.5;
  color: #666666;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuinmiNe2Euwb1601022382.png");
}

.noCanGet .left > div:nth-child(1) {
  color: #141414;
}

.noCanGet .left .price span {
  color: #000000;
}

.noCanGet .right > div {
  background: #dadada;
  color: #000000;
}

.explain {
  width: 9.02rem;
  height: auto;
  margin: 0.3rem auto;
  background: #fff;
  padding: 0.31rem 0.18rem;
  box-sizing: border-box;
  border-radius: 0.18rem;
  text-align: left;
  line-height: 1.6;
}

.explain > img {
  width: 20%;

  margin: 0 auto;
  margin-bottom: 0.34rem;
  display: block;
}

.n_bg {
  background: #f6f5f8;
  overflow: hidden;

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;

    .picture-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .picture {
        display: block;
        width: 280px;
        // height: 360px;
        background: rebeccapurple;
        margin: 0 auto;
      }

      .btn {
        width: 290px;
        height: 44px;
        border-radius: 22px;
        background: #ed2e2e;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        margin-top: 15px;
      }
    }

    .sku {
      width: 100%;
      min-height: 500px;
      border-radius: 8px 8px 0 0;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 14px;

      .tp {
        display: flex;
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        .goods-cover {
          width: 78px;
          height: 78px;
          border-radius: 4px;
          background: #e0e0e0;
        }

        .goods-price {
          width: 250px;
          height: 78px;
          display: flex;
          // align-items: center;
          flex-direction: column;
          justify-content: space-around;
          box-sizing: border-box;
          padding-left: 12px;

          .pris {
            text-align: left;

            .hjy {
              color: #ed2e2e;
              font-size: 13px;
            }

            .hjy-count {
              color: #ed2e2e;
              font-size: 18px;
              font-weight: bold;
              margin-right: 12px;
            }

            .spe-icon {
              font-size: 14px;
              color: #ed2e2e;
              font-weight: bold;
              margin-left: 4px;
            }

            .gwj {
              color: #141f33;
              font-size: 13px;
            }
          }

          .guige {
            text-align: left;
            font-size: 13px;
            color: #99a0ad;
          }

          .close {
            width: 16px;
            height: 16px;
            background: url("../../assets/images/closesku.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
      }

      .out {
        height: 200px;
        overflow: hidden;
        margin-bottom: 50px;

        .inner {
          height: 200px;
          overflow: auto;
        }
      }

      .status-box {
        width: 100%;
        margin-bottom: 8px;

        .tits {
          font-size: 16px;
          color: #141f33;
          margin-bottom: 12px;
          text-align: left;
        }

        .status {
          width: 100%;
          overflow: hidden;

          .box {
            width: auto;
            padding: 6px 20px;
            background: #f0f2f5;
            border-radius: 4px;
            font-size: 14px;
            color: #141414;
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 12px;
            float: left;
          }

          .act {
            background: #fae9e8;
            color: #ed2e2e;
          }
        }
      }
    }

    .num-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #141f33;
      margin-bottom: 23px;

      .handles {
        .edge,
        .add {
          display: inline-block;
          width: 28px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
        }

        .cou {
          display: inline-block;
          width: 45px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
          margin: 0 4px;
        }
      }
    }

    .code-txt {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #99a0ad;
      margin-bottom: 4px;
    }

    .btn-box {
      width: 100%;
      height: 44px;
      border-radius: 22px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .btn1 {
        width: 50%;
        height: 100%;
        line-height: 44px;
        background: #201e1d;
        color: #fff;
        font-size: 16px;
      }

      .btn2 {
        background: #ff001c;
      }

      .btn3 {
        width: 100%;
        height: 100%;
        line-height: 44px;
        color: #fff;
        font-size: 16px;
        background: #ff001c;
      }
    }
  }
}
</style>

<template>
  <div class="tigsBox" :style="{ height: tigsBoxH + 'px' }">
    <div class="tigsBoxPos">
      <div class="tigHeightBox" :style="{ height: showtigH + 'px' }">
        <div class="tigHeightText">
          点击右上角按钮，然后在弹窗的菜单中，点击“发送给 朋友”即可分享哦~
        </div>
        <div class="tigHeightArrow">
          <img src="@/assets/images/tigHeightArrow.png">
        </div>
      </div>
      <div v-show="textReady && showText" class="tops" :style="{ height:isclose? '1.06667rem':'0' }">
        <div class="les">
          <div class="close" @click="isclose" />
          <img class="logos" :src="textInfo.logo">
          <span>打开悦淘APP领红包</span>
        </div>
        <div v-if="isWeChat" class="ris wx-open-apptem">
          <wxOpenAppTem :opendata="openAppdata" />
        </div>
        <div v-else class="ris" @click="downLoadApp">立即打开</div>
      </div>
    </div>
  </div>
</template>

<script>
import { textData } from '@/services/goods.js'
import { wakeApp, SystemVersion } from '@/utils/public.js'
import wxOpenAppTem from '@/component/wxOpenAppTem'
import { isWeChat } from '@/utils/common/validate'
// getClientInfo
export default {
  components: {
    wxOpenAppTem
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      openAppdata: {
        text: '立即打开',
        wxExtinfo: this.options.info,
        handleLaunch: (e) => {

        }
      },
      showtigH: 0,
      tigsBoxH: 0,
      isWeChat: isWeChat(),
      textInfo: {}, // 顶部信息
      textReady: false, // 顶部信息是否请求完成
      showText: true // 是否显示打开App提示
    }
  },
  mounted() {
    this.textData()
  },
  methods: {
    SystemVersion() {
      return SystemVersion('isWeixin')
    },
    tigHeightChange() {
      if (
        this.options &&
        this.options.showtig &&
        isWeChat()
      ) {
        this.showtigH = 60
        if (this.textReady && this.showText) {
          this.tigsBoxH = 100
        } else {
          this.tigsBoxH = 60
        }
      } else {
        if (this.textReady && this.showText) {
          this.tigsBoxH = 40
        } else {
          this.tigsBoxH = 0
        }
      }
    },
    textData() {
      // 获取顶部数据信息
      textData({
        type: 1
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.textReady = true
          this.textInfo = res.data && res.data[0]
          this.tigHeightChange()
        }
      })
    },
    isclose() {
      this.showText = false
      this.$emit('isclose', false)
      this.tigHeightChange()
    },
    handleLaunchFn(e) {
    },
    handleErrorFn(e) {

    },
    downLoadApp() {
      const obj = this.options.info
      wakeApp(obj)
    }
  }
}
</script>

<style lang="less" scoped>
.tigsBox {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  //  z-index: 99;
  transition: 300ms;
  height: 0;
  overflow: hidden;

  .tigsBoxPos {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    .tigHeightBox {
      // transform: ;
      transition: 300ms;
      overflow: hidden;
      height: 0px;
      padding: 0 18px;
      background: rgba(48, 48, 48, 1);
      text-align: left;
      color: #999999;
      line-height: 18px;
      position: relative;

      .tigHeightText {
        padding: 13px 0;
        width: 310px;
      }

      .tigHeightArrow {
        width: 29px;
        height: 33px;
        position: absolute;
        top: 4px;
        right: 18px;

        img {
          width: 29px;
          height: 33px;
        }
      }
    }
  }
}

.tops {
  width: 100%;
  // height: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  // position: absolute;
  // top: 0;
  // left: 0;
  z-index: 99;

  .les {
    width: 295px;
    height: 40px;
    background: #000000;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #fff;

    .close {
      width: 17px;
      height: 17px;
      margin: 0 14px;
      background: url("../../assets/images/close.png") no-repeat;
      background-size: 100% 100%;
    }

    .logos {
      width: 29px;
      height: 29px;
      margin-right: 18px;
    }
  }

  .ris {
    width: 80px;
    height: 40px;
    background: #b83627;
    /*  background-color: blue;*/
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #fff;
  }
}

.btn {
  background: #000;

}
</style>

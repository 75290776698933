<template>
  <div class="wx-open-launch-box">
    <div v-if="$route.query.wh">{{ wxExtinfo }}</div>
    <div v-html="openAppInWx" />
  </div>
</template>
<script>
import { wakeApp } from '@/utils/public.js'
import { wxConfigWakeApp } from '@/utils/wx-api.js'
// import { upTruename } from '../../services/userApi'

export default {
  name: 'WxOpenAppTem',
  props: {
    opendata: {
      type: Object,
      default: null
    }
  },
  computed: {
    openAppInWx() {
      const { wxExtinfo, opendata } = this
      return '<wx-open-launch-app\n' +
        '      id="launch-btn"\n' +
        '      appid="wx735ebb8b05936d17"\n' +
        '      extinfo="' + wxExtinfo + '"\n' +
        '    >\n' +
        '      <script type="text/wxtag-template">\n' +
        '        <style>.btn {\n' +
        '        color: #fff;\n' +
        '        background: #b83627;\n' +
        '        width: 90px;\n' +
        '        height: 45px;\n' +
        '        display: flex;\n' +
        '        align-items: center;\n' +
        '        justify-content: center;\n' +
        '        font-size: 13px;\n' +
        '        }\n' +
        '        </style>\n' +
        '        <div class="btn">\n' + opendata.text +
        '        </div>\n' +
        '      <\/script>\n' +
        '    </wx-open-launch-app>'
    },
    wxExtinfo() {
      let redirectType = -1
      let str = ''
      if (this.opendata && this.opendata.wxExtinfo) {
        if (this.opendata.wxExtinfo.product_type) {
          switch (this.opendata.wxExtinfo.product_type) {
            case 1:
              redirectType = 3
              str = '?redirectType=3'

              break
            case 2:
              redirectType = 104
              str = '?redirectType=104'

              break
            case 3:
              redirectType = 88
              str = '?redirectType=88'
              break
            case 4:
              redirectType = 95
              str = '?redirectType=95'
              break
            case 5:
              redirectType = 110
              str = '?redirectType=110'
              break
            case 6:
              redirectType = 94
              str = '?redirectType=94'
              break
            default:
              redirectType = this.opendata.wxExtinfo.product_type
              str = '?redirectType=' + this.opendata.wxExtinfo.product_type
          }
          if (this.opendata && this.opendata.wxExtinfo) {
            for (const key in this.opendata.wxExtinfo) {
              if (key !== 'product_type' && this.opendata.wxExtinfo[key] && key !== 'codeNumber') {
                str += '&' + key + '=' + this.opendata.wxExtinfo[key]
              }
            }
          }
          if (this.$route.query.codeNumber) {
            str += '&codeNumber=' + this.$route.query.codeNumber
          }
          str += '&needLogin=1'
        }
      }

      console.log(redirectType)
      return 'https://www.yuetao.vip/ytApp/openPage' + str
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch('wxConfigWakeApp', {})
      wxConfigWakeApp()
    })
  },
  methods: {
    handleError(e) {
      wakeApp()
    }
  }
}
</script>
<style lang="less" scoped>
// .wx-open-launch-box {
  // overflow: hidden;
  // background: #000;
  //  width: 90px; height: 45px
// }

// #launch-btn {
  // width: 90px; height: 45px
// }

// .launch-btn{
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//     opacity: 0;
//     // background: red;
//   }
</style>
